import React, { useState } from 'react';
import Skills from './Skills';
import Certificates from './Certificates';
import About from './About';
import Works from './Works';
import Line from './Line';
import ThemeSwitcher from './ThemeSwitcher';
import HoverSound from "./HoverSound";
import ClickSound from "./ClickSound";

function Content() { 
  const [activeButton, setActiveButton] = useState('workHistory');
  const handleButtonClick = (button) => {
    setActiveButton(button);
  };
  return (
    <>
      <div className="flex flex-wrap w-full justify-center h-fu  ll relative ">
        <div className="hidden lg:flex"> 
          <Line />
        </div>
        <div className="flex flex-col w-full lg:w-3/6 border border-primary-2 relative lg:h-full flex  mb-6 md:mb-5 group/treangle">
          <div className="flex lg:hidden">
            <Line />
          </div>
          <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-3 pr-2 md:pl-6 md:pr-4">
            <h5 className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">to collaborate on • UI/AI  </h5>
            <div className="flex pl-5 lg:hidden items-center ml-auto relative">
              <span className="relative md:mr-2 h-2 w-2 flex items-center justify-center">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-7 opacity-90"></span>
                <span className="relative inline-flex rounded-full h-1 w-1 bg-primary-7 opacity-75"></span>
              </span>
              <div className="hidden md:flex items-center">
                <span className="text-primary-7 md:text-primary-7 hidden md:flex mr-2 text-min whitespace-nowrap">Mode-></span>
                <div className="flex  gap-2 md:gap-0 not-hover-opacity">
                  <ThemeSwitcher />
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 md:p-5 h-full overflow-y-auto flex flex-col">
            <Skills />
            <Certificates />
            <About />
          </div>
          <div className="border-t border-t-primary-2 min-h-10 h-10 flex items-center pl-4 mt-auto justify-center lg:justify-start pr-4">
            <svg
              viewBox="0 0 134 117.208"
              className="fill-primary-7 duration-150 transition group-hover/treangle:opacity-1 transform opacity-[0.39] rotate-180 w-5"
            >
              <path
                fill=""
                d="m0 0 57.167 99.667L94 36.333H73.167l5.041 8.75L57.667 82l-47-82z"
              ></path>
              <path
                fill=""
                d="m20.833 0 36.834 63.416L68.25 45.083H57.167L36.583 9.25h93.75L135.834 0z"
              ></path>
              <path
                fill=""
                d="M67.834 27.167 62.75 36 52.5 18.25h72.521L68 117.208l-5.375-8.625 46.75-81.5z"
              ></path>
            </svg>
          </div>
        </div>

        <div className=" w-full lg:w-3/6 border border-primary-2 lg:border-l-transparent relative lg:h-full flex flex-col group/treangle">
          <div className="flex lg:hidden">
            <Line />
          </div>
          <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-3 pr-2 md:pl-6 md:pr-4">
            <h5 className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">MY-WORKS • 2015 - now</h5>
            <div className="flex md:hidden lg:flex items-center ml-auto relative">
              <span className="relative md:mr-2 h-2 w-2 flex items-center justify-center">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-7 opacity-90"></span>
                <span className="relative inline-flex rounded-full h-1 w-1 bg-primary-7 opacity-75"></span>
              </span>
              <div className="hidden md:flex items-center">
                <span className="text-primary-7 md:text-primary-7 text-min lg:flex mr-2 hidden whitespace-nowrap">Mode-></span>
                <div className="flex  gap-2 md:gap-0 not-hover-opacity">
                  <ThemeSwitcher />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex p-2 md:p-5 gap-2 md:gap-5">
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3" className="w-full">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="w-full">
                <button
                  className={`
                  p-2 w-full text-sm h-10 min-h-10
                  border
                  hover:text-primary-7 hover:bg-primary-8
                  ${activeButton === 'workHistory' ? 'border-primary-11 hover:border-primary-11 bg-primary-8 text-primary-7' : 'text-primary-4 border-primary-8 hover:border-primary-8 '}
                `}
                  onClick={() => handleButtonClick('workHistory')}
                >   WORK HISTORY
                </button>
              </HoverSound>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3" className="w-full">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="w-full">
                <button
                  className={`
                  p-2 w-full text-sm h-10 min-h-10
                  border
                  hover:text-primary-7 hover:bg-primary-8
                  ${activeButton === 'presentationWorks' ? 'border-primary-11 hover:border-primary-11 bg-primary-8 text-primary-7' : 'text-primary-4 border-primary-8 hover:border-primary-8 '}
                 `}
                  onClick={() => handleButtonClick('presentationWorks')}
                >PRESENTATION WORK
                </button>
              </HoverSound>
            </ClickSound>
          </div>
          <div className={activeButton === 'workHistory' ? 'flex flex-col overflow-y-auto' : 'hidden'}>
            <Works />
          </div>
          <div className={activeButton === 'presentationWorks' ? 'flex h-full w-full flex-col' : 'hidden'}>
            <div className="flex flex-col h-full p-2 md:p-5 pt-0 md:pt-0">
              <div className="flex flex-col items-center justify-center px-2.5 py-2 md:px-5 md:py-4 border w-full h-full border-primary-2 relative transition duration-150">
              <span className="text-[69px]">&#x270C;&#xFE0F;
              </span>
                  <p className="text-primary-7 text-lg font-bold"> YOU GOT ME</p>
              </div>

            </div>
          </div>
          <div className="border-t border-t-primary-2 min-h-10 h-10 flex items-center justify-center lg:justify-end px-4 w-full mt-auto">
            <svg
              viewBox="0 0 134 117.208"
              className="fill-primary-7 duration-150 transition group-hover/treangle:opacity-1 transform opacity-[0.39] rotate-180 w-5"
            >
              <path
                fill=""
                d="m0 0 57.167 99.667L94 36.333H73.167l5.041 8.75L57.667 82l-47-82z"
              ></path>
              <path
                fill=""
                d="m20.833 0 36.834 63.416L68.25 45.083H57.167L36.583 9.25h93.75L135.834 0z"
              ></path>
              <path
                fill=""
                d="M67.834 27.167 62.75 36 52.5 18.25h72.521L68 117.208l-5.375-8.625 46.75-81.5z"
              ></path>
            </svg>
          </div>
        </div>
      </div>

    </>
  );
}
export default Content;
