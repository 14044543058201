import SideBar from './SideBar';
import Content from './Content';

function Wrapper() {
    return (
        <>
            <div className="flex flex-col md:flex-row h-full overflow-hidden relative mx-0 px-2 md:px-7 xl:px-9 w-full">
                <SideBar />
                <Content />
            </div>
        </>
    );
}

export default Wrapper;