import Line from './Line';
import HoverSound from "./HoverSound";
function Certificates() {
  return (
    <>
      <div className="flex flex-col border border-primary-2 lg:border-t-0 mb-2 md:mb-5 lg:mb-0 relative">
      <div className="hidden md:flex lg:hidden">
      <Line />
        </div>
        <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-2 md:px-5">
          <h5 className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">Certificates:</h5>
        </div>
        <ul className="p-2 md:p-5">
          <li className="flex mb-1">
            <HoverSound hoverSoundSrc="/sounds/process.mp3" className="flex">
              <a className="
                  py-1 px-2 text-xs md:text-sm
                  border border-primary-8 text-primary-7 hover:border-primary-11 hover:bg-primary-8
                  
                 " target="_blank" rel="noreferrer" href="https://www.hackerrank.com/profile/q1sh101">
                • HackerRank ->
              </a>
            </HoverSound>
          </li>
          <li className="flex mb-1">
            <HoverSound hoverSoundSrc="/sounds/process.mp3" className="flex">
              <a className="
                  py-1 px-2 text-xs md:text-sm
                  border border-primary-8 text-primary-7 hover:border-primary-11 hover:bg-primary-8
                  
                 " target="_blank" rel="noreferrer" href="https://coursera.org/share/c6d58bca47f2e6882b54792efddae0b1">
                • Coursera - Meta Front-End Developer ->
              </a>
            </HoverSound>
          </li>
          <li className="flex">
            <HoverSound hoverSoundSrc="/sounds/process.mp3" className="flex">
              <a className="
                  py-1 px-2 text-xs md:text-sm
                  border border-primary-8 text-primary-7 hover:border-primary-11 hover:bg-primary-8
                  
                 " target="_blank" rel="noreferrer" href="https://Coursera.org/share/db2ac2933213eba6f0ed1cad3338fb75">
                • Coursera - Google IT Support Professional ->
              </a>
            </HoverSound>
          </li>
        </ul>
      </div>
    </>
  );
}
export default Certificates;
