import HoverSound from "./HoverSound";
function Social() {
    return (
        <>
            <div className="fixed bottom-5 flex items-center justify-center font-normal z-3">
                <div className="h-12 flex justify-center transition-all duration-[0.4s] ease-in-out shadow-type-3 backdrop-blur-sm rounded-2xl bg-dock-primary">
                    <HoverSound className="dockitem group  relative origin-bottom transition-all duration-[0.4s] ease-in-out hov" hoverSoundSrc="/sounds/process.mp3" >
                        <a target="_blank" className="flex w-full h-full items-start" rel="noreferrer" href="https://github.com/q1sh101" >
                            <div className="absolute top-[10%] left-[50%] w-9 h-9 text-[19px] translate-x-[-50%] text-primary-7 bg-priamry-10 flex items-center justify-center rounded-[0.5em] border border-primary-10 icon-github"></div>
                            <span className="dockitem__txt relative opacity-0 bg-[#aaad] -top-4 block py-[0.1rem] py-1 text-center rounded-sm min-w-[3rem] group-hover:opacity-1 text-[0.4rem]">Github</span>
                        </a>
                    </HoverSound>
                    <HoverSound className="dockitem group relative origin-bottom transition-all duration-[0.4s] ease-in-out hov" hoverSoundSrc="/sounds/process.mp3" >
                        <a target="_blank" className="flex w-full h-full items-start" rel="noreferrer" href="https://www.upwork.com/freelancers/~01f1827f091b4c2e55" >
                            <div className="absolute top-[10%] left-[50%] w-9 h-9 text-[19px] translate-x-[-50%] text-primary-7 bg-priamry-10 flex items-center justify-center rounded-[0.5em] border border-primary-10 icon-upwork"></div>
                            <span className="dockitem__txt relative opacity-0 bg-[#aaad] -top-4 block py-[0.1rem] py-1 text-center rounded-sm min-w-[3rem] group-hover:opacity-1 text-[0.4rem]">Upwork</span>
                        </a>
                    </HoverSound>
                    <HoverSound className="dockitem group relative origin-bottom transition-all duration-[0.4s] ease-in-out hov" hoverSoundSrc="/sounds/process.mp3" >
                        <a target="_blank" className="flex w-full h-full items-start" rel="noreferrer" href="https://x.com/q1sh101" >
                            <div className="absolute top-[10%] left-[50%] w-9 h-9 text-[19px] translate-x-[-50%] text-primary-7 bg-priamry-10 flex items-center justify-center rounded-[0.5em] border border-primary-10 icon-twitter"></div>
                            <span className="dockitem__txt relative opacity-0 bg-[#aaad] -top-4 block py-[0.1rem] py-1 text-center rounded-sm min-w-[3rem] group-hover:opacity-1 text-[0.4rem]">Twitter</span>
                        </a>
                    </HoverSound>
                    <HoverSound className="dockitem group relative origin-bottom transition-all duration-[0.4s] ease-in-out hov" hoverSoundSrc="/sounds/process.mp3" >
                        <a target="_blank" className="flex w-full h-full items-start" rel="noreferrer" href="https://www.linkedin.com/in/giorgi-kishmareia-a0a396109/" >
                            <div className="absolute top-[10%] left-[50%] w-9 h-9 text-[19px] translate-x-[-50%] text-primary-7 bg-priamry-10 flex items-center justify-center rounded-[0.5em] border border-primary-10 icon-linkedin"></div>
                            <span className="dockitem__txt relative opacity-0 bg-[#aaad] -top-4 block py-[0.1rem] py-1 text-center rounded-sm min-w-[3rem] group-hover:opacity-1 text-[0.4rem]">Linkedin</span>
                        </a>
                    </HoverSound>
                    <HoverSound className="dockitem group relative origin-bottom transition-all duration-[0.4s] ease-in-out hov" hoverSoundSrc="/sounds/process.mp3" >
                        <a target="_blank" className="flex w-full h-full items-start" rel="noreferrer" href="https://discordapp.com/users/q1sh101" >
                            <div className="absolute top-[10%] left-[50%] w-9 h-9 text-[19px] translate-x-[-50%] text-primary-7 bg-priamry-10 flex items-center justify-center rounded-[0.5em] border border-primary-10 icon-discord"></div>
                            <span className="dockitem__txt relative opacity-0 bg-[#aaad] -top-4 block py-[0.1rem] py-1 text-center rounded-sm min-w-[3rem] group-hover:opacity-1 text-[0.4rem]">Discord</span>
                        </a>
                    </HoverSound>
                    <HoverSound className="dockitem group relative origin-bottom transition-all duration-[0.4s] ease-in-out hov" hoverSoundSrc="/sounds/process.mp3" >
                        <a target="_blank" className="flex w-full h-full items-start" rel="noreferrer" href="mailto:atomx101@outlook.com?subject=Hello!&body=Let's Touch !" >
                            <div className="absolute top-[10%] left-[50%] w-9 h-9 text-[19px] translate-x-[-50%] text-primary-7 bg-priamry-10 flex items-center justify-center rounded-[0.5em] border border-primary-10 icon-mail"></div>
                            <span className="dockitem__txt relative opacity-0 bg-[#aaad] -top-4 block py-[0.1rem] py-1 text-center rounded-sm min-w-[3rem] group-hover:opacity-1 text-[0.4rem]">Email</span>
                        </a>
                    </HoverSound>

                </div>
            </div >
        </>
    );
}
export default Social;
