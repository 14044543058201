import { useEffect, useState } from "react";
import { getBrowser, getOs, getFormattedDate, getFormattedTime } from "../utils/utils";

export const useCurrentInfo = () => {
  const [currentDate, setCurrentDate] = useState({
    time: "Loading",
    date: "Loading",
    day: "Loading",
    month: "Loading",
    year: "Loading",
    browser: "Loading",
  });
  const [uptime, setUptime] = useState(0);
  const [os, setOs] = useState("Detecting");

  useEffect(() => {
    const startTime = Date.now();

    // ფუნქცია, რომელიც ამოწმებს მიმდინარე ინფორმაციას
    const updateInfo = () => {
      const now = new Date();
      setCurrentDate((prev) => ({
        ...prev,
        time: getFormattedTime(now),
        ...getFormattedDate(now),
        browser: getBrowser(),
      }));
      setOs(getOs());
    };

    // განახლების პირველი წამოწყება
    updateInfo();

    // განახლების ინტერვალის დაწყება
    const interval = setInterval(() => {
      updateInfo();
      setUptime(Math.floor((Date.now() - startTime) / 1000));
    }, 1000);

    // ინტერვალის გაწმენდა კომპონენტის მოხსნისას
    return () => clearInterval(interval);
  }, []);

  return { currentDate, uptime, os };
};
