import React, { useRef, useEffect } from "react";
const ClickSound = ({ children, clickSoundSrc, className, volume = 0.3 }) => {
  const audioRef = useRef(null);
  useEffect(() => {
    audioRef.current = new Audio(clickSoundSrc);
    audioRef.current.preload = "auto";
    audioRef.current.volume = volume;

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [clickSoundSrc, volume]);

  const handleClick = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0; 
      audioRef.current
        .play()
        .catch((error) => {
          console.error("Audio playback failed:", error);
        });
    }
  };

  const handleMouseLeave = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  return (
    <div
      className={className}
      onClick={handleClick}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};
export default ClickSound;
