import Line from './Line';
function Scanner() {
  return (
    <>
      <div className="bg-grid group cursor-pointer flex items-center justify-center relative bg-center-2 w-[100%] h-[222px] min-h-[222px]  md:w-[100%] md:h-[136px] md:min-h-[136px]">
        <div className="hidden group-hover:flex md:w-24 md:h-24 w-32 h-32 absolute">
          <Line />
        </div>
        <div className="absolute left-0 top-0 w-full h-full flex justify-center  items-center z-1 overflow-hidden animate-pulse icon-finger font-thin text-[7rem] md:text-[5rem] text-primary-7">
        </div>
        <div className="w-full h-full overflow-hidden relative b border-l-0 border-r-0">
          <div className="w-full h-full translate-y-[-100%] animate-[radar] animate-duration-[3.6s] z-2 repeat-infinite absolute left-0 top-0 border-b border-b-primary-7 before:absolute before:bottom-0 before:left-0 before:w-full before:h-full before:content-['']  before:bg-green-down after:absolute after:top-full after:left-0 after:w-full after:h-full after:content-['']  repeat-infinite after:bg-green-top"></div>
        </div>
      </div>
    </>
  );
}
export default Scanner;
