export const getBrowser = () => {
    const userAgent = navigator.userAgent;
  
    const browserData = [
      { name: "Edge", criteria: ["EdgA", "Edg", "Edge"] },
      { name: "Opera", criteria: ["OPR", "Opera", "OPiOS"] },
      { name: "Brave", criteria: ["Brave"] },
      { name: "Chrome", criteria: ["Chrome", "CriOS"] },
      { name: "Firefox", criteria: ["Firefox", "FxiOS"] },
      { name: "DuckDuckGo", criteria: ["DuckDuckGo", "DuckDuckGo/"], exclude: ["Safari"] },
      { name: "Safari", criteria: ["Safari"], exclude: ["Chrome", "CriOS", "FxiOS", "DuckDuckGo"] },
      { name: "Explorer", criteria: ["MSIE", "Trident"] },
      { name: "Vivaldi", criteria: ["Vivaldi"] },
      { name: "Yandex", criteria: ["YaBrowser"] },
      { name: "Epic", criteria: ["Epic"] },
      { name: "Tor", criteria: ["TorBrowser"] },
      { name: "Pale Moon", criteria: ["PaleMoon"] },
      { name: "Waterfox", criteria: ["Waterfox"] },
      { name: "Samsung int", criteria: ["SamsungBrowser"] },
      { name: "Mi Browser", criteria: ["MiBrowser"] },
    ];
  
    for (const browser of browserData) {
      if (
        browser.criteria.some((criterion) => userAgent.includes(criterion)) &&
        !(browser.exclude || []).some((exclusion) => userAgent.includes(exclusion))
      ) {
        return browser.name;
      }
    }
  
    return "Unknown";
  };
  
  export const getOs = () => {
    const platform = navigator.platform;
    const userAgent = navigator.userAgent;
  
    const osData = [
      { name: "Windows", criteria: ["Win"] },
      { name: "MacOS", criteria: ["Mac"], exclude: ["iPhone", "iPad", "iPod"] },
      { name: "iOS", criteria: ["iPhone", "iPad", "iPod"], exclude: ["Mac"] },
      { name: "Android", criteria: ["Android"] },
      { name: "Kali Linux", criteria: ["Kali"] },
      { name: "Arch Linux", criteria: ["Arch"] },
      { name: "Ubuntu", criteria: ["Ubuntu"] },
      { name: "Fedora", criteria: ["Fedora"] },
      { name: "Debian", criteria: ["Debian"] },
      { name: "CentOS", criteria: ["CentOS"] },
      { name: "openSUSE", criteria: ["openSUSE"] },
      { name: "Linux", criteria: ["Linux"], exclude: ["Android"] },
    ];
  
    for (const os of osData) {
      if (
        os.criteria.some((criterion) => userAgent.includes(criterion) || platform.includes(criterion)) &&
        !(os.exclude || []).some((exclusion) => userAgent.includes(exclusion) || platform.includes(exclusion))
      ) {
        return os.name;
      }
    }
  
    return "Unknown";
  };
  
  export const getFormattedTime = (date) => {
    const options = { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false };
    return date.toLocaleTimeString("en-US", options);
  };
  
  export const getFormattedDate = (date) => {
    const options = { weekday: "long", year: "numeric", month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [day, month, dayOfMonth, year] = formattedDate.split(" ");
    return { day, month, date: dayOfMonth.replace(",", ""), year };
  };
  
  export const formatUptime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };