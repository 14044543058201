import { useState, useEffect } from "react";
import axios from "axios";

export const useFetchIP = () => {
  const [ip, setIP] = useState("");

  useEffect(() => {
    const fetchIP = async () => {
      const response = await axios.get("https://api.ipify.org/?format=json");
      setIP(response.data.ip);
    };
    fetchIP();
  }, []);

  return ip;
};
