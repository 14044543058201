import Line from './Line';
import HoverSound from "./HoverSound";
import ClickSound from "./ClickSound";
function Skills() {
  return (
    <>
      <div className="border border-primary-2 mb-2 md:mb-5 lg:mb-0 relative flex flex-col">
        <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-2 md:px-5">
          <h5 className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">skills:</h5>
        </div>
        <div className="hidden md:flex lg:hidden">
          <Line />
        </div>

        <div className="w-full flex flex-col items-center md:items-start overflow-y-auto">
         <div className="px-1 lg:5 xl:px-7 pt-9 font-normal ">
         <div className="flex mb-5 sm:mb-7 xl:mb-7">
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-agile">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap flex flex-col text-center leading-3"> 
                    <span>Agile & scrum</span>
                    <span>culture</span>
                  </span>
                </div>
              </HoverSound>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-figma">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">figma</span>
                </div>
              </HoverSound>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-html5">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">html5</span>
                </div>
              </HoverSound>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-css3">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">css3</span>
                </div>
              </HoverSound>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-scss">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">scss</span>
                </div>
              </HoverSound>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-tailwindcss">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">tailwind-css</span>
                </div>
              </HoverSound>
            </ClickSound>

          </div>
          <div className="flex mb-10 xl:ml-8 ml-[1.4rem] sm:ml-[1.9rem]">
          <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-reactjs">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">react-js</span>
                </div>
              </HoverSound>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-javascript">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">javascript</span>
                </div>
              </HoverSound>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-typescript">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">typeScript</span>
                </div>
              </HoverSound>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-node">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">node-js</span>
                </div>
              </HoverSound>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-git">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">git</span>
                </div>
              </HoverSound>
            </ClickSound>
            {/* <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-bash-shell">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">bash/shell</span>
                </div>
              </HoverSound>
            </ClickSound> */}
            {/* <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-vim">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">vim</span>
                </div>
              </HoverSound>
            </ClickSound> */}
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
              <HoverSound hoverSoundSrc="/sounds/process.mp3" className="group/skills cursor-pointer mx-1 sm:mx-2 relative hover:bg-primary-8 flex items-center justify-center h-4 min-w-9 min-h-4 sm:h-5 sm:min-w-11 sm:min-h-5 xl:w-12 xl:h-6 xl:min-w-12 xl:min-h-6 border-l border-r border-l-primary-7 border-r-primary-7 text-primary-7 text-md sm:text-lg xl:text-xl icon-code">
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <span className="group-hover/skills:bg-primary-8 absolute left-0 top-0 w-full h-full border-l rotate-minus-60 border-r border-l-primary-7 border-r-primary-7"></span>
                <div className="group-hover/skills:flex hidden absolute top-[-36px] border z-2 border-primary-7 pointer-events-none bg-base rounded-sm px-2">
                  <span className="text-xs text-primary-7 font-bold font-display uppercase whitespace-nowrap">software Development</span>
                </div>
              </HoverSound>
            </ClickSound>
          </div>
         </div>
        </div>
      </div >
    </>
  );
}
export default Skills;
