import Line from './Line';
function About() {
    return (
        <>
            <div className="flex flex-col border border-primary-2 lg:border-t-0 lg:mb-0 h-full relative">
            <div className="hidden md:flex lg:hidden">
            <Line />
                </div>
                <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-2 md:px-5">
                    <h5 className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">About Me:</h5>
                </div>
                <div className="flex flex-col p-2 md:p-5 text-justify">
                    {/* <span>
                        👋 Hey, I’m Giorgi—the Mind Behind the Code. 🌍
                        🐳 bio: atomic
                        👀 I’m interested in future typ0.
                        🌱 = to collaborate on UI - AI.
                    </span> */}
                    <p className="text-xs md:text-sm text-primary-7 mb-3">
                        Hey, I’m Giorg the Mind Behind the Code
                    </p>
                    <p className="text-xs md:text-sm text-primary-7 mb-3">
                        I don’t just write programs; I create experiences "Atom by atom" that captivate, inspire, and endure. With every project, I turn complexity into elegance, transforming ideas into digital art that speaks to the soul.
                    </p>
                    <p className="text-xs md:text-sm text-primary-7 mb-3">
                        My craft is a blend of precision and imagination. Every line of code is intentional, every design decision purposeful, It’s not just about solving problems it’s about creating something so extraordinary it leaves a lasting impression.
                    </p>
                    <p className="text-xs md:text-sm text-primary-7 mb-3">
                        If you’re looking for more than just a programmer, you’ve found a creator, a visionary, and a builder of unforgettable digital experiences. I am Giorgi, and I’m here to amaze.
                    </p>
                    <p className="text-xs md:text-sm text-primary-7 mb-3">
                        Let’s make the impossible—unforgettable... 🚀
                    </p>

                </div>
            </div>

        </>
    );
}
export default About;
