import React, { useRef, useEffect, useState } from "react";

const HoverSound = ({ children, hoverSoundSrc, className, volume = 0.03 }) => {
  const audioRef = useRef(null);
  const [isUserInteracted, setIsUserInteracted] = useState(false);
  const [isTouchOnly, setIsTouchOnly] = useState(false);

  const checkIfTouchOrHybrid = () => {
    const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    const isHybrid = window.matchMedia("(pointer: fine)").matches;
    return isTouchDevice && !isHybrid;
  };
  useEffect(() => {
    setIsTouchOnly(checkIfTouchOrHybrid());
  }, []);

  useEffect(() => {
    const enableAudio = () => {
      setIsUserInteracted(true);
      window.removeEventListener("click", enableAudio);
    };
    window.addEventListener("click", enableAudio);
    return () => window.removeEventListener("click", enableAudio);
  }, []);

  useEffect(() => {
    if (isUserInteracted && hoverSoundSrc && !isTouchOnly) {
      audioRef.current = new Audio(hoverSoundSrc);
      audioRef.current.preload = "auto";
      audioRef.current.volume = volume;
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [isUserInteracted, hoverSoundSrc, volume, isTouchOnly]);

  const handleMouseEnter = () => {
    if (audioRef.current && isUserInteracted && !isTouchOnly) {
      audioRef.current.currentTime = 0;
      audioRef.current
        .play()
        .catch((error) => {
          console.error("Audio playback failed:", error);
        });
    }
  };

  const handleMouseLeave = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  return (
    <div
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};

export default HoverSound;
